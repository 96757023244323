import React from 'react'
import ReactDOM from 'react-dom'
import { Route, Routes } from 'react-router-dom'
import './Globals.css'
import { LandingPagePhase2 } from './pages/LandingPagePhase2'
import { Providers } from './Providers'

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<LandingPagePhase2/>}/>
    </Routes>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <AppRoutes/>
    </Providers>
  </React.StrictMode>,
  document.getElementById('root'),
)
