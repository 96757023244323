import { DateTime } from 'luxon'

export const INFURA_ID = process.env.REACT_APP_INFURA_PROJECT_ID
export const SHOW_DEVELOPMENT_MENUS = (process.env.REACT_APP_SHOW_DEVELOPMENT_MENUS ?? 'true') === 'true'
export const INCLUDE_ALL_PHASE_ROUTES = process.env.NODE_ENV === 'development' || process.env.REACT_APP_INCLUDE_ALL_PHASE_ROUTES === 'true'

const SOMNET_BRIDGE_CONNECT_START_TIMESTAMP = process.env.REACT_APP_COUNTDOWN_START_TIMESTAMP ? parseInt(process.env.REACT_APP_COUNTDOWN_START_TIMESTAMP) : undefined
const SOMNET_BRIDGE_CONNECT_END_TIMESTAMP = process.env.REACT_APP_COUNTDOWN_END_TIMESTAMP ? parseInt(process.env.REACT_APP_COUNTDOWN_END_TIMESTAMP) : undefined

export const SOMNET_BRIDGE_CONNECT_START = SOMNET_BRIDGE_CONNECT_START_TIMESTAMP ? DateTime.fromMillis(SOMNET_BRIDGE_CONNECT_START_TIMESTAMP) : DateTime.fromMillis(1652803200000)
export const SOMNET_BRIDGE_CONNECT_END = SOMNET_BRIDGE_CONNECT_END_TIMESTAMP ? DateTime.fromMillis(SOMNET_BRIDGE_CONNECT_END_TIMESTAMP) : DateTime.fromMillis(1653076800000)
