import { HackerUIProvider } from '@chain-runners/ui'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { modifiedHackerUITheme } from './theme'

export const Providers: React.FC = ({ children }) => {
  return (
    <HackerUIProvider theme={modifiedHackerUITheme as any}>
      <BrowserRouter>
        {children}
      </BrowserRouter>
    </HackerUIProvider>
  )
}
