import { HackerButton, MotionBox } from '@chain-runners/ui'
import { Box, Flex, Image, Link, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import { BsBroadcast } from 'react-icons/bs'
import { ChainspaceBridgeLoadBar } from '../components/ChainspaceBridgeLoadBar'
import { ChainspaceTask } from '../components/ChainspaceTask'
import { DelayedReveal } from '../components/DelayedReveal'
import { SOMNET_BRIDGE_CONNECT_END, SOMNET_BRIDGE_CONNECT_START } from '../config/base'

const TWEET_TEXT = `Chainspace is opening on May 20. Be Ready. 

somnet.chainrunners.xyz
pic.twitter.com/ukwP3Y1NeF

https://twitter.com/chain_runners/status/1526593477215014918
`

export const LandingPagePhase2: React.FC = () => {
  const { isOpen, onClose, onOpen } = useDisclosure()

  return (
    <Flex
      minH="100vh"
      w="full"
      backgroundImage="/static/hacked_landing_page_background.png"
      backgroundSize="cover"
      backgroundPosition="center"
      justifyContent="center"
      alignItems="center"
      bgColor=""
    >
      <Flex direction="column" alignItems="center" maxW="90%">
        <MotionBox
          initial={{
            y: '-2vh',
            opacity: 0,
          }}
          animate={{
            y: 0,
            opacity: 1,
            transition: {
              ease: 'easeOut',
              stiffness: 100,
              duration: 0.2,
            },
          }}
        >
          <Image src="/static/zero_logo.svg" w="212px" maxW="full"/>
        </MotionBox>

        <Box mt="54px" w="850px" maxW="full">
          <ChainspaceBridgeLoadBar startTime={SOMNET_BRIDGE_CONNECT_START} endTime={SOMNET_BRIDGE_CONNECT_END}/>
        </Box>

        <Box w="600px" maxW="full" mt="42px" minH="250px">
          <ChainspaceTask>
            <Box p={3} fontFamily="mono">
              <Box mb={1} fontWeight="bold">
                REBOOT MEGA CITY
              </Box>
              <Box fontSize="13px">
                Flood the net with the Zero symbol and remind the people there is a world free from Somnus.
              </Box>
              <Box mt={2}>
                <Link
                  href={`https://twitter.com/intent/tweet?text=${encodeURI(TWEET_TEXT)}&hashtags=RebootMegaCity`}
                  target="_blank"
                  rel="noopener noreferrer"
                  cursor="pointer"
                  _hover={{ textDecoration: 'none' }}
                >
                  <HackerButton size="sm" filled display="flex" cursor="pointer">
                    <Box mr={2} fontSize="20px">
                      <BsBroadcast/>
                    </Box>
                    <Box fontWeight="semibold">
                      INITIATE HACK
                    </Box>
                  </HackerButton>
                </Link>
              </Box>
            </Box>
          </ChainspaceTask>

          <DelayedReveal delayInSeconds={0.7} pt={3}>
            <Box p={2} bgColor="hackerBlue" color="black" fontFamily="mono" fontSize="13px">
              By completing this task you will be entered to win a <Box as="span" fontWeight="semibold">FREE Chain
              Runner XR</Box>
            </Box>
          </DelayedReveal>

        </Box>
      </Flex>
    </Flex>
  )
}
