import { Box, BoxProps } from '@chakra-ui/react'
import React, { useEffect, useMemo, useState } from 'react'

export type TypingTextProps = Partial<BoxProps> & {
  messageText?: string
  skipToComplete?: boolean
  onComplete?: () => void
  children?: string
  speed?: number
  cursorSize?: number
  initialDelay?: number,
  loop?: boolean
  loopStart?: number
}

export const TypingText: React.FC<TypingTextProps> = ({
                                                        children,
                                                        messageText: messageTextProp,
                                                        skipToComplete,
                                                        onComplete,
                                                        speed = 1,
                                                        cursorSize = 16,
                                                        initialDelay = 0,
                                                        loop = false,
                                                        loopStart = 0,
                                                        ...props
                                                      }) => {
  const [writtenText, setWrittenText] = useState('')

  const messageText = useMemo(() => children ?? messageTextProp ?? '', [children, messageTextProp])

  useEffect(() => {
    setWrittenText('')
    const speedMultiplier = (1 - speed) + 1

    let cursor = 0
    let delay = initialDelay
    const interval = setInterval(() => {
      if (delay) {
        delay -= 1
        return
      }

      setWrittenText((writtenText) => {
        if (writtenText.length === messageText.length && !loop) {
          clearInterval(interval)
          return writtenText
        }

        return messageText.slice(0, cursor)
      })

      const endChar = ['.', '!', '?']
      if (endChar.includes(messageText.charAt(cursor - 1))) {
        delay = 25 * speedMultiplier
      }
      cursor += 1
      if (cursor === messageText.length + 1) {
        onComplete?.()
        if (loop) {
          cursor = loopStart
          delay = initialDelay
        } else {
          clearInterval(interval)
        }
      }
    }, 30 * speedMultiplier)
    return () => {

      clearInterval(interval)
    }
  }, [initialDelay, messageText, onComplete, speed, loop, loopStart])

  useEffect(() => {
    if (skipToComplete) {
      setWrittenText((writtenText) => {
        if (writtenText.length !== messageText.length) {
          onComplete?.()
        }
        return messageText
      })
    }
  }, [messageText, onComplete, skipToComplete])

  return (
    <Box position="relative" fontSize={['10px', null, '13px']} color="white" {...props}>
      <Box color="transparent">
        {messageText}
      </Box>
      <Box position="absolute" top={0} left={0} right={0} bottom={0}>
                  <span className="typingText">
                    {writtenText}
                  </span>
        {writtenText.length !== messageText.length && (
          <Box height={`${cursorSize}px`} className="typingCursor"/>
        )}
      </Box>
    </Box>
  )
}
