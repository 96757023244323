import { MotionBox } from '@chain-runners/ui'
import { Box, BoxProps, Flex } from '@chakra-ui/react'
import React from 'react'
import { BsExclamationSquareFill } from 'react-icons/bs'

export type ChainspaceTaskProps = Partial<BoxProps> & {
  transitionDirection?: 'left' | 'right'
}

export const ChainspaceTask: React.FC<ChainspaceTaskProps> = ({
  children,
  transitionDirection = 'right',
  ...props
}) => {
  return (
    <Box
      position="relative"
      borderColor="transparent"
      borderWidth="1px"
      {...props}
    >
      <Box opacity={0} pointerEvents="none" zIndex={-1}>
        <Flex alignItems="center" w="full" bgColor="hackerBlue" px="6px" py="1px" color="black">
          <Box mr={2}>
            <BsExclamationSquareFill/>
          </Box>
          <Box flex={1} fontWeight="semibold" fontFamily="mono">
            NEW TASK
          </Box>
        </Flex>
        <Box>
          {children}
        </Box>
      </Box>

      <MotionBox
        position="absolute"
        top={0}
        left={0}
        h="full"
        w="full"
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
          transition: {
            delay: 0.4,
            duration: 0.01,
          },
        }}
      >
        <MotionBox
          position="absolute"
          top={0}
          left={0}
          w="full"
          borderColor="hackerBlue"
          color="hackerBlue"
          borderWidth="1px"
          borderTopWidth="0px"
          initial={{
            height: '0%',
            x: transitionDirection === 'left' ? '-5vw' : '5vw',
          }}
          animate={{
            height: '100%',
            x: 0,
            y: 0,
            transition: {
              delay: 0.2,
              duration: 0.4,
              ease: 'easeInOut',
            },
          }}
          overflowY="hidden"
          zIndex={2}
        >
          <Flex alignItems="center" w="full" bgColor="hackerBlue" px="6px" py="1px" color="black">
            <Box mr={2}>
              <BsExclamationSquareFill/>
            </Box>
            <Box flex={1} fontWeight="semibold" fontFamily="mono">
              NEW TASK
            </Box>
          </Flex>
          <Box w="full">
            {children}
          </Box>
        </MotionBox>
      </MotionBox>
    </Box>
  )
}
