import { Box, BoxProps, useDisclosure } from '@chakra-ui/react'
import React, { useEffect } from 'react'

export type DelayedRevealProps = Partial<BoxProps> & {
  delayInSeconds?: number
  forceDisplay?: boolean
}

export const DelayedReveal: React.FC<DelayedRevealProps> = ({
  children,
  delayInSeconds = 1,
  forceDisplay = false,
  ...props
}) => {
  const { isOpen, onOpen } = useDisclosure()

  useEffect(() => {
    const timeout = setTimeout(onOpen, delayInSeconds * 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [delayInSeconds, onOpen])

  if (!isOpen || forceDisplay) return <div/>

  return (
    <Box className="delayed-reveal" {...props}>
      {children}
    </Box>
  )
}
