import { MotionBox } from '@chain-runners/ui'
import { Box, Flex, Image } from '@chakra-ui/react'
import { range } from 'lodash-es'
import { DateTime } from 'luxon'
import React, { useMemo } from 'react'
import { useNow } from '../hooks/useNow'
import { formatPercent } from '../utils/numbers'
import { TypingText } from './TypingText'

export type ChainspaceBridgeLoadBarProps = {
  startTime: DateTime
  endTime: DateTime
}
export const ChainspaceBridgeLoadBar: React.FC<ChainspaceBridgeLoadBarProps> = ({
                                                                                  endTime,
                                                                                  startTime,
                                                                                }) => {
  const now = useNow()
  const totalDuration = useMemo(() => {
    return endTime.diff(startTime)
  }, [endTime, startTime])

  const remainingDuration = useMemo(() => {
    return endTime.diff(DateTime.fromMillis(now))
  }, [now, endTime])

  const percent = useMemo(() => {
    return formatPercent(1 - (remainingDuration.toMillis() / totalDuration.toMillis()), 1)
  }, [totalDuration, remainingDuration])

  const remainingDurationCountdownComponents = useMemo(() => {
    return remainingDuration.toFormat('dd:hh:mm:ss').split(':')
  }, [remainingDuration])

  return (
    <Flex w="full" alignItems="center" maxW="full">
      <Image height="118px" width="76px" src="/static/chainspace_connection_tunnel.svg"/>
      <Flex
        flex={1}
        h="118px"
        w="full"
        position="relative"
      >
        <MotionBox
          position="absolute"
          top={0}
          left={0}
          initial={{
            width: '0%',
          }}
          animate={{
            width: '100%',
            transition: {
              duration: 0.8,
              ease: 'easeInOut',
            },
          }}
          h="full"
          borderWidth="1px"
          borderColor="hackerGreen"
          borderLeftStyle="none"
          overflow="hidden"
        >
          <Flex
            flexDir="column"
            justifyContent="space-between"
            h="full"
            p={3}
          >
            <Flex overflowX="hidden" color="black" bgColor="hackerGreen" fontSize="13px" px={2} py="2px"
                  alignItems="center">
              <Box flex={1} whiteSpace="nowrap" pr={2}>
                CHAINSPACE BRIDGE CONNECTING
              </Box>
              <Flex display={['none', 'flex']} alignItems="center">
                {range(4).map((index) => {
                  return (
                    <Box
                      key={index}
                      bgColor="black"
                      height="4px"
                      width="4px"
                      _notLast={{
                        mr: '4px',
                      }}
                    />
                  )
                })}
              </Flex>
            </Flex>

            <Flex py="6px" w={['100%', '210px']} justifyContent="space-between" alignItems="center">
              {remainingDurationCountdownComponents.map((text, index) => {
                return (
                  <Box key={index}>{text}</Box>
                )
              })}
            </Flex>

            <Box position="relative" height="2px" width="full" bgColor="rgba(255, 255, 255, 0.2)">
              <Box
                position="absolute"
                top={0}
                bottom={0}
                left={0}
                width={percent}
              >
                <MotionBox
                  initial={{ width: '0%' }}
                  animate={{
                    width: '100%',
                    transition: {
                      stiffness: 100,
                      duration: 1,
                      delay: 0.6,
                    },
                  }}
                  height="full"
                  bgColor="hackerGreen"
                />
              </Box>
            </Box>

            <Flex mt={2} justifyContent="space-between" alignItems="center" fontSize="14px">
              <TypingText color="hackerGreen" fontSize="14px" loop loopStart={8} initialDelay={30} cursorSize={0}>
                Cracking...
              </TypingText>
              <Box>
                {percent}
              </Box>
            </Flex>
          </Flex>
        </MotionBox>

      </Flex>
    </Flex>
  )
}
